@import 'bootstrap/dist/css/bootstrap.min.css';
@import "../public/assets/styles/responsive.scss";
@import "../public/assets/styles/wing-custom.scss";
@import "../public/assets/styles/wing-theme.scss";


html, body { height: 100%; width: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.page-title {
  background-color: #fff;
  border-bottom: 1px solid #D9D9E1;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1),
              0px 20px 30px rgba(0, 0, 0, 0.16) inset;
  margin: 0 -27px 30px;
  padding: 12px 30px;
}

.page-title h3 {
  font-size: 24px;
  padding: 5px 30px 0;
}

.title-container {
  position: sticky;
    top: 0;
  z-index: 5;
}